

























import { defineComponent, SetupContext, toRef } from '@vue/composition-api'

import { useErrors, useSyncModel } from '../../composables'
import { TextareaProps } from './Textarea.contracts'
import { customTextareaProps } from './Textarea.hooks'

/**
 *
 * Component capable to render `<textarea />` component with all passed-in args, <br />
 * with possibility to handle invalid state of input or show form errors in a custom way.
 *
 * @displayName Textarea
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
export const Textarea = defineComponent({
  name: 'CustomTextarea',
  props: customTextareaProps,

  setup (props: TextareaProps, ctx: SetupContext) {
    const { clearErrors, hasErrors } = useErrors(toRef(props, 'errors'), ctx.emit)

    const value = useSyncModel(toRef(props, 'model'), ctx.emit, clearErrors)
    const inputPlaceholder = (props.required && !props.hasVisibleLabel) ? props.placeholder + '*'
      : props.placeholder

    return { value, hasErrors, inputPlaceholder }
  }
})
export default Textarea
